export namespace PROJECT {
  export enum NAME {
    // 2024
    BELL = "bell",
    SUbWAY_NAVIGATION = "subway-navigation",
    // 2023
    ANURO_TTA_GS = "anuro-tta-gs",
    // 2022
    BIODOME = "biodome",
    // 2021
    HELPER_IT = "helper-it",
    WEBMASTER = "webmaster"
  }

  export const toString = (name: NAME) => {
    switch (name) {
      //  2024
      case PROJECT.NAME.BELL:
        return "벨(BELL)";
      case PROJECT.NAME.SUbWAY_NAVIGATION:
        return "지하철 내비게이션";
      //  2023
      case PROJECT.NAME.ANURO_TTA_GS:
        return "스마트시티 플랫폼 - ANURO (TTA 인증, GS 1등급 취득)";
      //  2022
      case PROJECT.NAME.BIODOME:
        return "BIODOME";
      //  2021
      case PROJECT.NAME.HELPER_IT:
        return "헬퍼잇";
      case PROJECT.NAME.WEBMASTER:
        return "웹마스터 - 홈페이지 유지보수";
    }
  }
}

export const ZEROWEB_CAREER = {
  "2021": [
    {
      title: "웹마스터",
      startDate: "2021-06-14",
      endDate: "2024-10-25",
      color: "#434343",
      url: "/career/zeroweb/project/webmaster",
    },
    {
      title: "PCP",
      startDate: "2021-06-01",
      endDate: "2021-07-31",
      color: "#f4cccc",
    },
    {
      title: "헬퍼잇",
      startDate: "2021-08-26",
      endDate: "2021-09-30",
      color: "#72ccd3",
      url: "/career/zeroweb/project/helper-it",
    },
    {
      title: "마사회",
      startDate: "2021-09-14",
      endDate: "2021-09-30",
      color: "#b6d7a8",
    },
    {
      title: "시민체감형 스마트시티 구축 사업",
      startDate: "2021-09-14",
      endDate: "2022-02-15",
      color: "#c9daf8",
    },
    {
      title: "지하철 내비게이션",
      startDate: "2021-11-09",
      endDate: "2024-03-08",
      color: "#ffd966",
      url: "/career/zeroweb/project/subway-navigation",
    },
  ],
  "2022": [
    {
      title: "BIODOME",
      startDate: "2022-02-23",
      endDate: "2022-03-07",
      color: "#b4a7d6",
      url: "/career/zeroweb/project/biodome",
    },
    {
      title: "스마트 시티 플랫폼 - ANURO (TTA 인증)",
      startDate: "2022-06-01",
      endDate: "2023-02-28",
      color: "#4a86e8",
      url: "/career/zeroweb/project/anuro-tta-gs",
    },
    {
      title: "직급 대리를 달다!! 🥳",
      startDate: "2022-11-07",
      endDate: "2022-11-07",
      color: "#fa8d8d",
    },
  ],
  "2023": [
    {
      title: "스마트 시티 플랫폼 - ANURO (GS 1급 인증)",
      startDate: "2023-05-30",
      endDate: "2023-10-17",
      color: "#4a86e8",
      url: "/career/zeroweb/project/anuro-tta-gs",
    },
    {
      title: "BELL",
      startDate: "2023-06-01",
      endDate: "2024-10-25",
      color: "#ed3d6c",
      url: "/career/zeroweb/project/bell",
    },
  ],
  "2024": [
    {
      title: "부산태권도협회 - 사이트 추가개발 건",
      startDate: "2024-06-12",
      endDate: "2024-08-17",
      color: "#434343",
      // url: "/career/zeroweb/project/busantaekwondo",
    }
  ],
};
